import React from "react";
import ReactGA from 'react-ga4';
const Hireme = () => {
	const handleButtonClick = () => {
  ReactGA.event({
    category: 'Button',
    action: 'click_say_hello_contact',
    label: 'Say Hello contact'
  });
};
  return (
    <section id="hireme" className="py-10 px-3 text-white bg-gray-800">
      <div className="text-center">
        <h3 className="text-4xl font-semibold">
          Hire <span className="text-cyan-600">Me</span>
        </h3>
        <p className="text-gray-400 mt-3 text-lg">Do you have any work?</p>
      </div>
      <div className="bg-gray-700 relative px-8 rounded-2xl py-5 lg:max-w-4xl mx-auto min-h-[24rem] mt-24 flex gap-6 lg:flex-row flex-col-reverse items-center">
        <div>
          <h2 className="text-2xl font-semibold">
            Build you project with me
          </h2>
          <p className="lg:text-left text-justify max-w-lg text-sm mt-4 text-gray-200 leading-6">
            I use my technical skills and creativity to develop websites and
            applications that meet the needs of my clients or the end-users. I
            work closely with my clients to understand their requirements,
            analyze their business goals, and develop strategies that align with
            their overall objectives.
          </p>
          <a href="#contact">
	  <button onClick={handleButtonClick} className="btn-primary mt-10 w-32">
            Say Hello
	  </button>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Hireme;
