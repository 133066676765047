import React from "react";
import { AiFillHtml5 } from "react-icons/ai";
import { DiJavascript } from "react-icons/di";
import { FaReact } from "react-icons/fa";
import { IoLogoNodejs } from "react-icons/io";
import { SiCss3 } from "react-icons/si";
import { TbBrandTypescript } from "react-icons/tb";

const Skills = () => {
  const skills = [
    {
      logo: <FaReact />,
      level: "REACT",
      count: 90,
    },
    {
      logo: <TbBrandTypescript />,
      level: "TYPESCRIPT",
      count: 90,
    },
    {
      logo: <DiJavascript />,
      level: "JAVASCRIPT",
      count: 90,
    },
    {
      logo: <IoLogoNodejs />,
      level: "NODE",
      count: 90,
    },
    {
      logo: <AiFillHtml5 />,
      level: "HTML",
      count: 90,
    },
    {
      logo: <SiCss3 />,
      level: "CSS",
      count: 90,
    },
  ];
  return (
    <section id="skills" className="py-10  relative">
      <div className="mt-8 text-gray-100 text-center">
        <h3 className="text-4xl font-semibold">
          My <span className="text-cyan-600">Skills</span>
        </h3>
        <p className="text-gray-400 mt-3 text-lg">Core Competencies</p>
        <div className="flex items-center justify-center mt-12 gap-10 flex-wrap">
          {skills?.map((skill, i) => (
            <div
              key={i}
              className="border-2 group border-cyan-600 relative min-w-[10rem] max-w-[16rem] bg-gray-900 p-10 rounded-xl"
            >
              <div
                style={{
                  background: `conic-gradient(rgb(8,145,170) ${skill.count}%,#ddd ${skill.count}%)`,
                }}
                className="w-32 h-32 flex items-center justify-center rounded-full"
              >
                <div className="text-6xl w-28 h-28 bg-gray-900 rounded-full flex items-center justify-center group-hover:text-cyan-600">
                  {skill.logo}
                </div>
              </div>
              <p className="text-xl mt-3">{skill.level}</p>
              <span className="text-xs text-gray-400">Expert</span>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Skills;
