import React from "react";
import ReactGA from 'react-ga4';

const About = () => {
	const handleButtonClick = () => {
  ReactGA.event({
    category: 'Button',
    action: 'download_cv',
    label: 'Download CV'
  });
};

  const info = [
    { text: "Years experience", count: "07" },
    { text: "Completed Projects", count: "10" },
    { text: "Companies Work", count: "03" },
  ];
  return (
    <section id="about" className="py-10 text-white bg-gray-800">
      <div className="text-center mt-8">
        <h3 className="text-4xl font-semibold">
          About <span className="text-cyan-600">Me</span>
        </h3>
        <p className="text-gray-400 my-3 text-lg">My introduction</p>
        <div className="flex md:flex-row flex-col-reverse items-center md:gap-6 gap-12 px-10 max-w-6xl mx-auto">
          <div className="p-2">
            <div className="text-gray-300 my-3">
              <p className="text-justify leading-7 w-11/12 mx-auto">
                As a web developer, I have excellent problem-solving skills,
                attention to detail, and a passion for creating engaging and
                functional web experiences. I am a team player and collaborate
                effectively with other professionals such as designers, project
                managers, and content creators to ensure that web development
                projects are completed on time and within budget.
              </p>
              <div className="flex mt-10 items-center gap-7">
                {info.map((content) => (
                  <div key={content.text}>
                    <h3 className="md:text-4xl text-2xl font-semibold text-white">
                      {content.count}
                      <span className="text-cyan-600">+</span>{" "}
                    </h3>
                    <span className="md:text-base text-xs">{content.text}</span>
                  </div>
                ))}
              </div>
              <br />
              <br />
              <a href="./cv.pdf" download>
                <button onClick={handleButtonClick} className="btn-primary">Download CV</button>
              </a>
            </div>
          </div>
        </div>

      </div>
    </section>
  );
};

export default About;
