import React from "react";
import { useState, useRef } from "react";
import emailjs from "@emailjs/browser";

import { MdEmail, MdLocationOn } from "react-icons/md";
import { BsWhatsapp } from "react-icons/bs";

import { Toaster } from "react-hot-toast";
import toast from "react-hot-toast";
import ReactGA from 'react-ga4';

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const form = useRef();

  const contact_info = [
    { logo: <MdEmail />, text: "ben.dekens@gmail.com" },
    { logo: <BsWhatsapp />, text: "+33749038284" },
    {
      logo: <MdLocationOn />,
      text: "Paris",
    },
  ];

	const handleButtonClick = () => {
  ReactGA.event({
    category: 'Button',
    action: 'click_contact_form',
    label: 'Contact form'
  });
};

  const submitHandler = (e) => {
    e.preventDefault();

    // check for empty fields
    if (
      name.trim().length === 0 ||
      email.trim().length === 0 ||
      message.trim().length === 0
    ) {
      toast.error("all fields are required");
      return;
    }
    if (name.length < 6) {
      // name validation
      toast.error("name must be at least 6 characters");
      return;
    }

    // email validation
    if (!email.includes("@")) {
      toast.error("please enter a valid email address");
      return;
    }

    // message validation
    if (message.length < 20) {
      toast.error("message must be at least 120 characters");
      return;
    }

    // Send Email
    emailjs
      .sendForm(
        "service_oedh1tv",
        "template_5caovkl",
        form.current,
       "WXMuWBwciLbs47CPL",
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    toast.success("Message Sent");
  };

  return (
    <section id="contact" className="py-10 px-3 text-white bg-gray-800">
      <Toaster />
      <div className="text-center mt-8">
        <h3 className="text-4xl font-semibold">
          Contact <span className="text-cyan-600">Me</span>
        </h3>
        <p className="text-gray-400 mt-3 text-lg">Get in touch</p>

        <div
          className="mt-16 flex md:flex-row flex-col
         gap-6 max-w-5xl bg-gray-800 md:p-6 p-2 rounded-lg mx-auto"
        >
          <form
            ref={form}
            className="flex flex-col flex-1 gap-5"
            onSubmit={submitHandler}
          >
            <input
              onChange={(e) => setName(e.target.value)}
              value={name}
              type="text"
              id="name"
              name="name"
              placeholder="Your Name"
            />
            <input
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              type="text"
              id="email"
              name="email"
              placeholder="Your Email Address"
            />
            <textarea
              onChange={(e) => setMessage(e.target.value)}
              value={message}
              placeholder="Your Message"
              id="message"
              name="message"
              rows={10}
            ></textarea>
            <button onClick={handleButtonClick} className="btn-primary w-fit">Send Message</button>
          </form>
          <div className="flex flex-col  gap-7 ">
            {contact_info.map((contact, i) => (
              <div
                key={i}
                className="flex flex-row
                  text-left gap-4 flex-wrap items-center"
              >
                <div className="min-w-[3.5rem]  text-3xl min-h-[3.5rem] flex items-center justify-center text-white bg-cyan-600 rounded-full">
                  {contact.logo}
                </div>
                <p className="md:text-base text-sm  break-words">
                  {contact.text}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
