import React, { useState } from "react";
import { Autoplay, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import project7 from "../assets/images/manomano.png";
import project4 from "../assets/images/project-autocomplete.png";
import project3 from "../assets/images/project-digital-photo-frame.png";
import project6 from "../assets/images/project-esport-tournaments.png";
import project1 from "../assets/images/project-exchange-currency.png";
import project5 from "../assets/images/project-real-estate-estimation.png";
import project2 from "../assets/images/project-robot-production-line.png";

const Project = () => {
  const [fullscreen, setFullscreen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleImageClick = (index) => {
    setActiveIndex(index);
    setFullscreen(!fullscreen);
  };
  const projects = [
    {
      img: project7,
      name: "eCommerce - ManoMano",
      live_link:
        "https://www.manomano.fr/catalogue/p/vidaxl-salon-de-jardin-5-pcs-avec-coussins-resine-tressee-gris-18484812?product_id=18801564",
    },
    {
      img: project2,
      name: "Gaming - Robot production line Game",
      github_link: "https://github.com/ben-2/foobartory-front",
      live_link: "https://ben-dekens-foobartory.netlify.app/",
    },
    {
      img: project3,
      name: "Art - Digital photo frame",
      live_link: "https://ben-dekens-digital-photo-frame.netlify.app/",
    },
    {
      img: project1,
      name: "Finance - Exchange Currency",
      github_link: "https://github.com/ben-2/currency-swap",
      live_link: "https://ben-dekens-currency-exchange-mobile.netlify.app/",
    },
    {
      img: project4,
      name: "Services - Responsive Autocomplete",
      // github_link: "https://github.com/ben-2/autocomplete-deel",
      live_link: "https://ben-dekens-deel-autocomplete.netlify.app",
    },
    {
      img: project5,
      name: "Real estate - agency estimate",
      live_link: "https://ben-dekens-site-vitrine-immo.netlify.app/",
    },
    {
      img: project6,
      name: "Gaming - Tournament eSport",
      // github_link: "https://github.com/ben-2/game-tournaments-coding-challenge",
      live_link: "https://ben-dekens-esport.netlify.app/",
    },
  ];
  return (
    <section id="projects" className="py-10 text-white">
      <div className="text-center">
        <h3 className="text-4xl font-semibold">
          My <span className="text-cyan-600">Projects</span>
        </h3>
        <p className="text-gray-400 mt-3 text-lg">Portfolio</p>
      </div>
      <br />
      <div className="flex max-w-6xl gap-6 px-5 mx-auto items-center relative">
        <div className="w-full ">
          <Swiper
            slidesPerview={1.2}
            spaceBetween={20}
            breakpoints={{
              768: {
                slidesPerView: 2,
              },
            }}
            loop={true}
            autoplay={{
              delay: 3000,
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination, Autoplay]}
            onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
          >
            {projects.map((project_info, i) => (
              <SwiperSlide key={i} style={{ position: "unset" }}>
                <div className="h-fit w-full p-4 bg-slate-700 rounded-xl sliderPadding">
                  <img
                    src={project_info.img}
                    alt={project_info.name}
                    className="rounded-lg"
                    onClick={() => handleImageClick(i)}
                    style={{ cursor: "pointer" }}
                  />

                  <h3 className="text-xl my-4">{project_info.name}</h3>
                  <div className="flex gap-3 sliderMargin">
                    {project_info.github_link && (
                      <a
                        href={project_info.github_link}
                        target="_blank"
                        rel="noreferrer"
                        className="text-cyan-600 bg-gray-800 px-2 py-1 inline-block"
                      >
                        Github
                      </a>
                    )}
                    {project_info.live_link && (
                      <a
                        href={project_info.live_link}
                        target="_blank"
                        rel="noreferrer"
                        className="text-cyan-600 bg-gray-800 px-2 py-1 inline-block"
                      >
                        Live Demo
                      </a>
                    )}
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          {fullscreen && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                background: "rgba(0, 0, 0, 0.8)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 9999,
              }}
              onClick={handleImageClick}
            >
              <div
                style={{
                  position: "absolute",
                  top: "1rem",
                  right: "1rem",
                  cursor: "pointer",
                  color: "#ccc",
                  fontSize: "2rem",
                }}
                onClick={() => setFullscreen(false)}
              >
                &times;
              </div>
              <img
                src={projects[activeIndex].img}
                alt={projects[activeIndex].name}
                style={{
                  maxWidth: "90%",
                  maxHeight: "90%",
                  borderRadius: "6px",
                }}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Project;
