import React from "react";
import { BiMessageSquareDetail } from "react-icons/bi";
import ReactGA from 'react-ga4';
const Bot = () => {
	const handleButtonClick = () => {
  ReactGA.event({
    category: 'Button',
    action: 'click_floating_btn',
    label: 'Click floating button'
  });
};
  return (
    <a
      href="#contact"
    >
	  <button onClick={handleButtonClick} className="fixed bottom-5 sm:right-8 right-4 z-[999] cursor-pointer text-white text-4xl bg-cyan-600 w-16 h-16 flex items-center justify-center rounded-full animate-bounce">
      <BiMessageSquareDetail />
	  </button>
    </a>
  );
};

export default Bot;
